import type { QBus } from "@otto-ec/global-resources/event-q-bus";
import { buildTrackingPayloadCinema, buildTrackingPayloadSlide } from "./TrackingPayload";
import { TrackingService } from "../TrackingService";
import type { CarouselSlideChangedEvent } from "./CarouselSlideChangedEvent";
import type { CarouselMountedEvent } from "./CarouselMountedEvent";
import { moduleLogger } from "../util/Logger";
import type { Feature } from "@otto-ec/tracking-bct";

const log = moduleLogger.scope("carrousel");

export class PapercardBenefitCarousel {
	private trackedAsVisible: string[] = [];

	constructor(
		private readonly eventQBus: QBus,
		private readonly trackingService: TrackingService = new TrackingService(),
	) {}

	init() {
		this.eventQBus.on("ft9.benefit.init", () => {
			log.info("FT-RepTile has duplicated the cinema -> going to call assets to init them");
			this.eventQBus.emit("pattern.carousel.init", ".benefit_context_cinema");
		});

		this.eventQBus.on("pattern.carousel.mounted", (event: CarouselMountedEvent) => {
			const cinemaDom = event.element;
			if (cinemaDom.classList.contains("benefit_context_cinema") && !("mounted" in cinemaDom.dataset)) {
				cinemaDom.dataset.mounted = "true";

				const cinemaId = cinemaDom.parentElement?.parentElement?.getAttribute("data-feature-index") ?? "1";
				log.info("Receiving initialized event from asset for a cinema with id: %s -> going to register events and scarcity countdown", cinemaId);

				this.sendLoadedTracking(cinemaDom, event.currentSlides, cinemaId);
				this.registerVisibleTracking(cinemaDom, event.currentSlides, cinemaId);

				for (const benefit of Array.from(cinemaDom.getElementsByClassName("ft9-benefit-paper-card"))) {
					for (const elementWithSheetOpen of benefit.getElementsByClassName("js_openInPaliSheet")) {
						const sheetUrl = atob(elementWithSheetOpen.getAttribute("data-sheet-ub64e") as string);
						const encodedSheetUrl = btoa(`${sheetUrl}&featurePosition=${cinemaId}`);
						elementWithSheetOpen.setAttribute("data-sheet-ub64e", encodedSheetUrl);
					}
				}
			}
		});

		this.eventQBus.on("pattern.carousel.slide.changed", (event: CarouselSlideChangedEvent) => {
			const cinemaDom = event.element;
			if (cinemaDom.classList.contains("benefit_context_cinema") && event.scrollingRight) {
				log.info("Receiving change event from asset for a cinema -> going to track:", { currentSlides: event.currentSlides, scrollingRight: event.scrollingRight, source: event.source });

				const visibleCinemaPayload = buildTrackingPayloadCinema("visible", cinemaDom);
				const cinemaSlides = cinemaDom.getElementsByClassName("ft9-benefit-paper-card");

				const visibleTrackingData: Feature[] = [];
				for (const id of event.currentSlides) {
					const nowVisibleElement = cinemaSlides[id] as HTMLElement;
					if (nowVisibleElement.dataset.trackingState !== "visible") {
						nowVisibleElement.dataset.trackingState = "visible";
						visibleTrackingData.push(buildTrackingPayloadSlide("visible", nowVisibleElement, visibleCinemaPayload.id));
					}
				}

				if (visibleTrackingData.length > 0) {
					this.trackingService.sendEvent({
						name: "next",
						features: [visibleCinemaPayload, ...visibleTrackingData],
					});
				}
			}
		});

		/*                                        */
		/*                                                                            */
		this.eventQBus.emit("pattern.carousel.init", ".benefit_context_cinema");
	}

	sendLoadedTracking(cinemaDom: HTMLElement, currentVisibleSlides: number[], cinemaId: string) {
		const firstCinema = cinemaId === "1";

		if (firstCinema) {
			const loadedTrackingData: Feature[] = [];
			const loadedCinemaPayload = buildTrackingPayloadCinema("loaded", cinemaDom);
			loadedTrackingData.push(loadedCinemaPayload);

			const cinemaSlides = cinemaDom.getElementsByClassName("ft9-benefit-paper-card");
			for (let i = 0; i < cinemaSlides.length; i++) {
				const benefitElement = cinemaSlides[i] as HTMLElement;
				if (currentVisibleSlides.includes(i)) {
					loadedTrackingData.push(buildTrackingPayloadSlide("loaded", benefitElement, loadedCinemaPayload.id));
				} else {
					loadedTrackingData.push(buildTrackingPayloadSlide("hidden", benefitElement, loadedCinemaPayload.id));
				}
			}

			this.trackingService.sendMergeForCinema(loadedTrackingData);
		}
	}

	registerVisibleTracking(cinemaDom: HTMLElement, currentVisibleSlides: number[], cinemaId: string) {
		if (!this.trackedAsVisible.includes(cinemaId)) {
			this.trackedAsVisible.push(cinemaId);
			this.registerSeenHandlerCallback(cinemaDom, () => {
				const visibleCinemaPayload = buildTrackingPayloadCinema("visible", cinemaDom);
				const cinemaSlides = cinemaDom.getElementsByClassName("ft9-benefit-paper-card");

				const visibleTrackingData: Feature[] = [];

				for (const id of currentVisibleSlides) {
					const visibleElement = cinemaSlides[id] as HTMLElement;
					visibleElement.dataset.trackingState = "visible";
					visibleTrackingData.push(buildTrackingPayloadSlide("visible", visibleElement, visibleCinemaPayload.id));
				}

				this.trackingService.sendEvent({
					name: "scroll",
					features: [visibleCinemaPayload, ...visibleTrackingData],
				});
			});
		}
	}

	registerSeenHandlerCallback(element: HTMLElement, callback: () => void) {
		if (window.IntersectionObserver) {
			const observer = new window.IntersectionObserver(
				(e, o) => {
					const entry = e[0];
					if (entry?.isIntersecting) {
						o.unobserve(element);
						callback();
					}
				},
				{ threshold: 0.75 },
			);
			observer.observe(element);
		}
	}
}
